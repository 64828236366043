// Project-wide CSS goes here
// Include any other CSS files here

// // We have to be careful to not override Bootstrap's variables for common colors
// // like "gray" or "black", so I'm just throwing in literal hex codes in a few spots.
$dmp-yellow: #fed51b;
$dmp-dark-gray: #495057;
$dmp-black: #000000;

$link-color: $dmp-black;
$link-hover-color: $dmp-yellow;
$nav-pills-link-active-bg: $dmp-yellow;
$nav-pills-link-active-color: $dmp-black;
$nav-link-color: $dmp-dark-gray;
$nav-link-hover-color: $dmp-yellow;

@import 'bootstrap';

.btn-dmp-yellow {
  @include button-variant($dmp-yellow, $dmp-yellow, $hover-background: $dmp-black, $hover-border: $dmp-black, $hover-color: $dmp-yellow);
}

.btn-outline-dmp-yellow {
  @include button-outline-variant($dmp-yellow);
}

.alert-dmp {
  background-color: $dmp-yellow;
  border-color: darken($dmp-yellow, 7.5%); // Adjust the darken percentage as needed
  color: $dmp-black;
}
